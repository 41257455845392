html, body, #root {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
